<template>
   <v-app style="background-color: #edeadb;
  background-image: url(https://cenoteszapote.com//img/bg-zapote.jpg);
  background-repeat: repeat;
  background-position: 0 0;">
      <router-view />
  </v-app> 
</template>

<script>
//import NavBar from '@/components/NavBar';
//import foot from'@/components/foot';

export default {
  name: 'App',
  data: () => ({

  }), 
  /*components: {
    NavBar,
    foot,
  }*/

  
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
.nav{
  font-family: 'Anton', sans-serif;
}
*{
  font-family: 'Montserrat', sans-serif;
}
</style>
