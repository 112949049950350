/*exports.credentials = {
  apiKey: "AIzaSyC9-Q9D9uqu2vmPV8nhhPeU5aMfezxJTcE",
  authDomain: "zapote-dev.firebaseapp.com",
  projectId: "zapote-dev",
  storageBucket: "zapote-dev.appspot.com",
  messagingSenderId: "176778461573",
  appId: "1:176778461573:web:44fc99f705d55d4fbf04ec"
  //measurementId: "G-4BERY6KQ2F"
}*/
exports.credentials = {
  apiKey: "AIzaSyAvN2rPKBj2jSQSyF4IBX5y9wB0PB4FcSA",
  authDomain: "cenoteszapote-5293b.firebaseapp.com",
  databaseURL: "https://cenoteszapote-5293b.firebaseio.com",
  projectId: "cenoteszapote-5293b",
  storageBucket: "cenoteszapote-5293b.appspot.com",
  messagingSenderId: "633819237895",
  appId: "1:633819237895:web:25f11fecaa5ccbbd3564a2",
  measurementId: "G-4BERY6KQ2F"
}
/*exports.credentials = {
  apiKey: "AIzaSyCTes-IT0knzJngKCH2Ete1aJ2X7npAJH4",
  authDomain: "monkeysatvscancun.firebaseapp.com",
  projectId: "monkeysatvscancun",
  storageBucket: "monkeysatvscancun.appspot.com",
  messagingSenderId: "366775048683",
  appId: "1:366775048683:web:18801601c5a7e7096761ae"
}*/