<template>
  <v-app class="panel-divers">
    <!-- <sidebar-menu @toggle-collapse="onToggleCollapse" :menu="menu" :relative="false" :showOneChild="true" width="250px" style="height: 100vh"> -->
    <sidebar-menu @toggle-collapse="onToggleCollapse" :menu="menu" :relative="false" :showOneChild="true" :collapsed="true" width="250px" style="height: 100vh">
      <div slot="header" class="">
        <div class="header-menu">
          <v-img
            alt="Vuetify Logo"
            class="shrink d-flex"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            width="90"
          />
        </div>
        <!-- <h1 class="name-app">Cenotes Zapote</h1> -->
        <v-divider></v-divider>
      </div>
      <span slot="toggle-icon"><v-icon class="text-white">mdi-arrow-left-right-bold</v-icon></span>
    </sidebar-menu>
    <div ref="app-bar" class=" app-bar elevation-5 width-250px" style="left: 50px" >
    <!-- <div ref="app-bar" :class="(this.openSidebar ? 'width-250px' : 'width-50px'  )+' app-bar elevation-5'"> -->
      <div class="app-bar-title"><portal-target name="title-page"></portal-target></div>
      <div class="app-bar-options">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs"
                    v-on="on">
                <v-avatar color="indigo" size="36">
                  <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
                </v-avatar>
                <v-icon>mdi-dots-vertical</v-icon>
              </div>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <img src="https://randomuser.me/api/portraits/men/85.jpg" alt="John">
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{userName}}</v-list-item-title>
                    <v-list-item-subtitle>{{userRole}}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      :class="fav ? 'red--text' : ''"
                      icon
                      @click="fav = !fav"
                    >
                      <v-icon>mdi-heart</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list dense shaped>
                <v-list-item-group color="primary">
                  <!-- <v-list-item>
                    <v-list-item-icon>
                      <v-icon v-text="'mdi-cog'"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <router-link to="/backend/admin/my-account" tag="span">
                          Mi cuenta
                        </router-link>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item> -->
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon v-text="'mdi-logout-variant'"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title @click="logout()">
                        Salir
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-divider></v-divider>
              <v-card-actions>
                <!-- <v-spacer></v-spacer> -->

                <!-- <v-btn text @click="menu2 = false">Cancel</v-btn> -->
                <v-btn color="primary" text @click="menu2 = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
      </div>
    </div>
    <v-main ref="app-main" style="margin-left: 50px;" class="bg-content">
      <!-- <v-card style="" class="d-flex">
        <v-card-text class="bg-content"> -->
            <div class="bg-content">
              <!-- <v-row>
                <v-col
                  cols="12"
                > -->
                  <div class="mx-4 my-5">
                    <router-view  :key="$route.fullPath"></router-view>
                  </div>
                <!-- </v-col>
              </v-row> -->
            </div>
        <!-- </v-card-text>
      </v-card> -->
    </v-main>
  </v-app>
</template>
<script>
  // @ is an alias to /src
  import firebase from 'firebase/app';
  import 'firebase/auth';
  import User from '@/models/User.js';

  export default {
    name:'App',
    components: {},
    data:() => {
      return {
        fav: true,
        menu2: false,
        openSidebar:false,
        user:null,
        menu: [
          /*{
            header: true,
            title: 'Menu',
            hiddenOnCollapse: true
          },*/
          {
            href: '/backend/divers',
            title: 'Inicio',
            icon: {
              element: 'i',
              class: 'mdi mdi-home'
            }
          },
          {
            href: '/backend/divers/reservationsList',
            title: 'Reservaciones',
            icon: {
              element: 'i',
              class: 'mdi mdi-playlist-check'
            }
          }
        ]
      }
    },
    methods:{
      logout() {
        this.$loading.show();
        firebase.auth().signOut().then(()=>{
          location.reload()
        })
      },
      onToggleCollapse() {
        this.openSidebar = !this.openSidebar;
        let elMain = this.$refs["app-main"].$el
        let elBar = this.$refs["app-bar"]//.$el
        if (this.openSidebar) {
          elMain.classList.add('slideMainToRight');
          elMain.classList.remove('slideMainToLeft');
          elBar.classList.add('slideBarToRight');
          elBar.classList.remove('slideBarToLeft');
        } else {
          elMain.classList.add('slideMainToLeft');
          elMain.classList.remove('slideMainToRight');
          elBar.classList.add('slideBarToLeft');
          elBar.classList.remove('slideBarToRight');
        }
      }
    },
    mounted() {
      let currentUser = firebase.auth().currentUser;
      let usersModel = new User();
      //usersModel.findByUID(currentUser.uid).get().then(qSnapshot => {
      usersModel.findByUID(currentUser.uid).onSnapshot(qSnapshot => {
        if (!qSnapshot.empty) {
          qSnapshot.forEach(doc => {
            if (doc.exists) {
              this.user = doc.data();
            }
          })
        }
      })
    },
    computed:{
      userName:function() {
        return this.user ? this.user.name : '';
      },
      userRole:function() {
        return this.user ? this.user.role : '';
      }
    }
  }
</script>
<style>
  .vsm--mobile-item{
    /*z-index: 10000 !important;*/
  }
  .vsm--mobile-bg{
    /*z-index: 10000 !important;*/
  }
  .v-sidebar-menu{
    z-index: 50 !important;
  }

  .panel-divers .app-bar{
    position: fixed;
    z-index: 5;
    /*padding: 19px;*/
    height: 70px;
    background-color: #F6F7F9;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    /* width: calc(100% - 250px); */
    width: calc(100% - 50px);
  }
  .app-bar h1{
    font-size: 1.4rem;
  }
  .app-bar-options{
    margin-left: auto;
  }
  .v-main{
    margin-top: 70px;
  }
  .slideMainToLeft {
    animation-duration: 0.4s;
    animation-name: slideMainToLeft;
    animation-fill-mode: forwards;
  }
  .slideMainToRight {
    animation-duration: 0.4s;
    animation-name: slideMainToRight;
    animation-fill-mode: forwards;
  }
  .slideBarToLeft {
    animation-duration: 0.4s;
    animation-name: slideBarToLeft;
    animation-fill-mode: forwards;
  }
  .slideBarToRight {
    animation-duration: 0.4s;
    animation-name: slideBarToRight;
    animation-fill-mode: forwards;
  }

  @keyframes slideMainToLeft {
    from {
      margin-left: 250px;
    }

    to {
      margin-left: 50px;
    }
  }

  @keyframes slideMainToRight {
    from {
      margin-left: 50px;
    }

    to {
      margin-left: 250px;
    }
  }

  @keyframes slideBarToLeft {
    from {
      left: 250px;
      width: calc(100% - 250px);
    }

    to {
      left: 50px;
      width: calc(100% - 50px);
    }
  }

  @keyframes slideBarToRight {
    from {
      left: 50px;
      width: calc(100% - 50px);
    }

    to {
      left: 250px;
      width: calc(100% - 250px);
    }
  }
  .v-application .col-xl, .v-application .col-xl-auto, .v-application .col-xl-12, .v-application .col-xl-11, .v-application .col-xl-10, .v-application .col-xl-9, .v-application .col-xl-8, .v-application .col-xl-7, .v-application .col-xl-6, .v-application .col-xl-5, .v-application .col-xl-4, .v-application .col-xl-3, .v-application .col-xl-2, .v-application .col-xl-1, .v-application .col-lg, .v-application .col-lg-auto, .v-application .col-lg-12, .v-application .col-lg-11, .v-application .col-lg-10, .v-application .col-lg-9, .v-application .col-lg-8, .v-application .col-lg-7, .v-application .col-lg-6, .v-application .col-lg-5, .v-application .col-lg-4, .v-application .col-lg-3, .v-application .col-lg-2, .v-application .col-lg-1, .v-application .col-md, .v-application .col-md-auto, .v-application .col-md-12, .v-application .col-md-11, .v-application .col-md-10, .v-application .col-md-9, .v-application .col-md-8, .v-application .col-md-7, .v-application .col-md-6, .v-application .col-md-5, .v-application .col-md-4, .v-application .col-md-3, .v-application .col-md-2, .v-application .col-md-1, .v-application .col-sm, .v-application .col-sm-auto, .v-application .col-sm-12, .v-application .col-sm-11, .v-application .col-sm-10, .v-application .col-sm-9, .v-application .col-sm-8, .v-application .col-sm-7, .v-application .col-sm-6, .v-application .col-sm-5, .v-application .col-sm-4, .v-application .col-sm-3, .v-application .col-sm-2, .v-application .col-sm-1, .v-application .col, .v-application .col-auto, .v-application .col-12, .v-application .col-11, .v-application .col-10, .v-application .col-9, .v-application .col-8, .v-application .col-7, .v-application .col-6, .v-application .col-5, .v-application .col-4, .v-application .col-3, .v-application .col-2, .v-application .col-1 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .v-text-field{
    margin-top: 0;
  }
</style>