'use strict'

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import reservation from './reservation'
/*export const store = new Vuex.Store({
  state: {
    layout: 'front'
  },
  mutations: {
    SET_LAYOUT (state, newLayout) {
      state.layout = newLayout
    }
  },
  actions:{
    setDialogConfirmedInfo(context, dialog) {
      context.commit('SET_DIALOG_CONFIRMED_INFO', dialog);
    }
  }
})*/
const debug = process.env.NODE_ENV !== 'production'
export const store = new Vuex.Store({
  state: {
    layout: 'front'
  },
  mutations: {
    SET_LAYOUT (state, newLayout) {
      state.layout = newLayout
    }
  },
  actions:{
    setDialogConfirmedInfo(context, dialog) {
      context.commit('SET_DIALOG_CONFIRMED_INFO', dialog);
    }
  },
  modules: {
    reservation
  },
  strict: debug,
  //plugins: debug ? [createLogger()] : []
})