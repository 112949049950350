class User {
  constructor(data) {
    this.id = data.id;
    this.uid = data.uid;
    this.email = data.email;
    this.password = data.password || '';
    this.role = data.role;
    this.name = data.name || '';
    this.lastname = data.lastname || '';
    this.business = data.business || '';
    this.phone = data.phone || '';
    this.businessType = data.businessType || '';
    this.hasDived = data.hasDived || false;
    this.certificationFile = data.certificationFile || ''
    this.status = data.status || 'inactive';
    this.registerDate = data.registerDate || null;
  }
}
var DataConverter = {
  toFirestore: function(user) {
    return {
      //id: user.id,
      uid: user.uid,
      email: user.email,
      password: user.password || '',
      role: user.role,
      name: user.name,
      lastname: user.lastname,
    }
  },
  fromFirestore: function(snapshot, options){
    const data = snapshot.data(options);
    data.id = snapshot.id
    // return new User(snapshot.id, data.uid, data.email, data.password, data.role, data.name, data.lastname, data.status, data.hasPermissions)
    return new User(data)
  }
}
export {User, DataConverter} 