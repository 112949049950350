<template>
  <v-app >
    <sidebar-menu @toggle-collapse="onToggleCollapse" :menu="menu" :relative="false" :showOneChild="true" width="250px" style="height: 100vh">
      <div slot="header" class="">
        <div class="header-menu">
          <v-img
            alt="Vuetify Logo"
            class="shrink d-flex"
            contain
            src="@/assets/logo.png"
            transition="scale-transition"
            width="90"
          />
        </div>
        <!-- <h1 class="name-app">Cenotes Zapote</h1> -->
        <v-divider></v-divider>
      </div>
      <span slot="toggle-icon"><v-icon class="text-white">mdi-arrow-left-right-bold</v-icon></span>
    </sidebar-menu>
    <div ref="app-bar" class=" app-bar elevation-5 width-250px" style="left: 250px" >
      <!-- class="(this.openSidebar ? 'width-250px' : 'width-50px'  )+' app-bar elevation-5'" -->
      <div class="app-bar-title"><portal-target name="title-page"></portal-target></div>
      <div class="app-bar-options">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-width="200"
            offset-x
          >
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs"
                    v-on="on">
                <v-avatar color="indigo" size="36">
                  <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
                </v-avatar>
                <v-icon>mdi-dots-vertical</v-icon>
              </div>
            </template>

            <v-card>
              <v-list>
                <v-list-item>
                  <v-list-item-avatar>
                    <img src="https://randomuser.me/api/portraits/men/85.jpg" alt="John">
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title>{{userName}}</v-list-item-title>
                    <v-list-item-subtitle>{{userRole}}</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      :class="fav ? 'red--text' : ''"
                      icon
                      @click="fav = !fav"
                    >
                      <v-icon>mdi-heart</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>

              <v-divider></v-divider>

              <v-list dense shaped>
                <v-list-item-group color="primary">
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon v-text="'mdi-cog'"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <router-link to="/backend/admin/my-account" tag="span">
                          Mi cuenta
                        </router-link>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon v-text="'mdi-logout-variant'"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title @click="logout()">
                        Salir
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
              <v-divider></v-divider>
              <v-card-actions>
                <!-- <v-spacer></v-spacer> -->

                <!-- <v-btn text @click="menu2 = false">Cancel</v-btn> -->
                <v-btn color="primary" text @click="menu2 = false">Cerrar</v-btn>
              </v-card-actions>
            </v-card>
          </v-menu>
      </div>
    </div>
    <v-main ref="app-main" style="margin-left: 250px;" class="bg-content">
      <!-- <v-card style="" class="d-flex">
        <v-card-text class="bg-content"> -->
            <v-container class="bg-content">
              <v-row>
                <v-col
                  cols="12"
                >
                  <div class="mx-8">
                    <router-view  :key="$route.fullPath"></router-view>
                  </div>
                </v-col>
              </v-row>
            </v-container>
        <!-- </v-card-text>
      </v-card> -->
    </v-main>
  </v-app>
</template>
<script>
  // @ is an alias to /src
  import firebase from 'firebase/app';
  import 'firebase/auth';
  import User from '@/models/User.js';

  export default {
    name:'App',
    components: {},
    data:() => {
      return {
        fav: true,
        menu2: false,
        openSidebar:true,
        user:null,
        menu: [
          /*{
            header: true,
            title: 'Menu',
            hiddenOnCollapse: true
          },*/
          {
            href: '/backend/admin',
            title: 'Dashboard',
            icon: {
              element: 'i',
              class: 'mdi mdi-home',
              // attributes: {}
              //text: 'mdi-home'
            }
          },
          {
            href: '/backend/admin/reservations',
            title: 'Reservaciones',
            icon: {
              element: 'i',
              class: 'mdi mdi-playlist-check',
            },
            child: [
              {
                href: '/backend/admin/reservations',
                title: '- Reservaciones'
              },
            ]
          },
          {
            href: '/backend/admin/leads',
            title: 'Landing leads',
            icon: {
              element: 'i',
              class: 'mdi mdi-playlist-check',
            },
            // child: [
            //   {
            //     href: '/backend/admin/leads',
            //     title: '- Reservaciones'
            //   },
            // ]
          },
          {
            href: '/backend/admin/tours',
            title: 'Tours',
            icon: {
              element: 'i',
              class: 'mdi mdi-car-estate',
            },
            child: [
              {
                href: '/backend/admin/tours',
                title: '- Tours'
              },
              {
                href: '/backend/admin/tours/create',
                title: '- Agregar tour'
              },
              {
                href: '/backend/admin/transport',
                title: '- Transporte'
              },
              {
                href: '/backend/admin/transport/create',
                title: '- Agregar transporte'
              },
              {
                href: '/backend/admin/zone',
                title: '- Zonas'
              },
              {
                href: '/backend/admin/zone/create',
                title: '- Agregar zonas'
              }
            ]
          },
          {
            href: '/backend/admin/extras',
            title: 'Extras',
            icon: {
              element: 'i',
              //class: 'mdi mdi-chevron-down-circle',
              class: 'mdi mdi-cart-plus',
            },
            child: [
              {
                href: '/backend/admin/extras',
                title: '- Extras'
              },
              {
                href: '/backend/admin/extras/create',
                title: '- Agregar extra'
              },
              {
                href: '/backend/admin/categories-extras',
                title: '- Categorias'
              },
              {
                href: '/backend/admin/categories-extras/create',
                title: '- Agregar Categoria'
              },
              /*{
                href: '/backend/admin/categories-extras/create',
                title: '- Agregar Categoria'
              }*/
            ]
          },
          {
            href: '/backend/admin/cupones',
            title: 'Cupones',
            icon: {
              element: 'i',
              class: 'mdi mdi-ticket-percent',
            },
            child: [
              {
                href: '/backend/admin/cupones',
                title: '- Listado'
              },
              {
                href: '/backend/admin/cupones/create',
                title: '- Agregar'
              }
            ]
          },
          {
            href: '/backend/admin/currencies',
            title: 'Monedas',
            icon: {
              element: 'i',
              class: 'mdi mdi-currency-usd',
            },
            child: [
              {
                href: '/backend/admin/currencies',
                title: '- Listado'
              },
              {
                href: '/backend/admin/currencies/create',
                title: '- Agregar'
              }
            ]
          },
          {
            href: '/backend/admin/reports/customers',
            title: 'Reportes',
            icon: {
              element: 'i',
              class: 'mdi mdi-file-table',
            },
            child: [
              {
                href: '/backend/admin/reports/customers',
                title: '- Clientes'
              },
            ]
          },
          {
            href: '/backend/admin/users',
            title: 'Usuarios',
            icon: {
              element: 'i',
              class: 'mdi mdi-account-multiple',
            },
            child: [
              {
                href: '/backend/admin/users',
                title: '- Usuarios'
              },
              {
                href: '/backend/admin/users/permissions',
                title: '- Permisos para Aut.'
              },
            ]
          },
          {
            href: '/backend/admin/divers',
            title: 'Buzos',
            icon: {
              element: 'i',
              class: 'mdi mdi-diving-helmet',
            },
            child: [
              {
                href: '/backend/admin/divers',
                title: '- Buzos'
              },
              {
                href: '/backend/admin/divers/rates',
                title: '- Tarifas'
              },
            ]
          },
          {
            //href: '/backend/admin/cms',
            title: 'CMS',
            icon: {
              element: 'i',
              class: 'mdi mdi-application-cog-outline',
            },
            child: [
              {
                href: '/backend/admin/cms/landings',
                title: 'Landings',
                child:[
                  {
                    href: '/backend/admin/cms/landings',
                    title: '- Listado'
                  },
                  {
                    href: '/backend/admin/cms/landings/create',
                    title: '- Agregar'
                  },
                ]
              },
              {
                href: '/backend/admin/cms/landings',
                title: 'Home',
                child:[
                  {
                    href: '/backend/admin/cms/home-landings-title',
                    title: '- Titulo landings'
                  },
                  {
                    href: '/backend/admin/cms/home-tours',
                    title: '- Tours'
                  },
                  {
                    href: '/backend/admin/cms/home-landings',
                    title: '- Landings'
                  },
                ]
              },
            ]
          },
        ]
      }
    },
    methods:{
      logout() {
        this.$loading.show();
        firebase.auth().signOut().then(()=>{
          location.reload()
        })
      },
      onToggleCollapse() {
        this.openSidebar = !this.openSidebar;
        let elMain = this.$refs["app-main"].$el
        let elBar = this.$refs["app-bar"]//.$el
        if (this.openSidebar) {
          elMain.classList.add('slideMainToRight');
          elMain.classList.remove('slideMainToLeft');
          elBar.classList.add('slideBarToRight');
          elBar.classList.remove('slideBarToLeft');
        } else {
          elMain.classList.add('slideMainToLeft');
          elMain.classList.remove('slideMainToRight');
          elBar.classList.add('slideBarToLeft');
          elBar.classList.remove('slideBarToRight');
        }
      }
    },
    mounted() {
      let currentUser = firebase.auth().currentUser;
      let usersModel = new User();
      //usersModel.findByUID(currentUser.uid).get().then(qSnapshot => {
      usersModel.findByUID(currentUser.uid).onSnapshot(qSnapshot => {
        if (!qSnapshot.empty) {
          qSnapshot.forEach(doc => {
            if (doc.exists) {
              this.user = doc.data();
            }
          })
        }
      })
    },
    computed:{
      userName:function() {
        return this.user ? this.user.name : '';
      },
      userRole:function() {
        return this.user ? this.user.role : '';
      }
    }
  }
</script>
<style>
  .app-bar{
    position: fixed;
    z-index: 5;
    /*padding: 19px;*/
    height: 70px;
    background-color: #F6F7F9;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    width: calc(100% - 250px);
  }
  .app-bar h1{
    font-size: 1.4rem;
  }
  .app-bar-options{
    margin-left: auto;
  }
  .v-main{
    margin-top: 70px;
  }
  .slideMainToLeft {
    animation-duration: 0.4s;
    animation-name: slideMainToLeft;
    animation-fill-mode: forwards;
  }
  .slideMainToRight {
    animation-duration: 0.4s;
    animation-name: slideMainToRight;
    animation-fill-mode: forwards;
  }
  .slideBarToLeft {
    animation-duration: 0.4s;
    animation-name: slideBarToLeft;
    animation-fill-mode: forwards;
  }
  .slideBarToRight {
    animation-duration: 0.4s;
    animation-name: slideBarToRight;
    animation-fill-mode: forwards;
  }

  @keyframes slideMainToLeft {
    from {
      margin-left: 250px;
    }

    to {
      margin-left: 50px;
    }
  }

  @keyframes slideMainToRight {
    from {
      margin-left: 50px;
    }

    to {
      margin-left: 250px;
    }
  }

  @keyframes slideBarToLeft {
    from {
      left: 250px;
      width: calc(100% - 250px);
    }

    to {
      left: 50px;
      width: calc(100% - 50px);
    }
  }

  @keyframes slideBarToRight {
    from {
      left: 50px;
      width: calc(100% - 50px);
    }

    to {
      left: 250px;
      width: calc(100% - 250px);
    }
  }
</style>