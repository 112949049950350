import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app';
import 'firebase/auth';
import User from '@/models/User.js';

Vue.use(VueRouter)

const routes = [
  /*{
    path: '/',
    name: 'Home',
    component: () =>
        import ('./views/Home.vue')
  },
  {
    path: '/reserva',
    name: 'reserva',
    component: () =>
        import ('./views/Reservar.vue')
  },
  {
    path: '/quintanaroo',
    name: 'quintanaroo',
    component: () =>
        import ('./views/Quintanarro.vue')
  },*/
  {
    path: '/',
    name: 'Login',
    component: () =>
        import ('./views/Login.vue')
  },
  /*{
    path: '/backend/login',
    name: 'Login',
    component: () =>
        import ('./views/Login.vue')
  },*/
  {
    path: '/forbidden',
    name: 'Forbidden',
    component: () => import ('./views/PageForbidden.vue')
  },
  {
    path: '/backend/admin',
    //name: 'Admin',
    component: () => import ('./views/admin/Admin.vue'),
    meta: {
      requiresAuth: true,
      role: 'admin'
    },
    children: [
      {
        path: '',
        name: 'AdminDashboard',
        component: () => import ('./views/admin/Dashboard.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        }
      },
      {
        path: 'reservations',
        name: 'reservations',
        component: () => import ('./views/admin/Reservations.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
      {
        path: 'leads',
        name: 'AdminLeads',
        component: () => import ('./views/admin/Leads.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
      {
        path: 'tours',
        name: 'tours',
        component: () => import ('./views/admin/Tours.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
      {
        path: 'tours/create',
        name: 'create',
        component: () =>
            import ('./views/admin/TourCreate.vue'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'tours/:Id/edit',
        name: 'edit',
        component: () =>
            import ('./views/admin/TourEdit.vue'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'tours/:Id/images',
        name: 'TourImages',
        component: () =>
            import ('./views/admin/TourImages.vue'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'tours/:Id/extras',
        name: 'TourExtras',
        component: () =>
            import ('./views/admin/TourExtras.vue'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'cupones',
        name: 'cupones',
        component: () =>
            import ('./views/admin/Cupones.vue'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'cupones/create',
        name: 'cuponesCreate',
        component: () =>
            import ('./views/admin/CuponesCreate.vue'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'cupones/:id/edit',
        name: 'cuponEdit',
        component: () =>
            import ('./views/admin/CuponesEdit.vue'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'cupones/:Id/images',
        name: 'CuponesImages',
        component: () =>
            import ('./views/admin/CuponImages.vue'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'tours/:tourId/rates',
        name: 'Rates',
        component: () =>
            import ('./views/admin/Rates.vue'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'tours/:tourId/rates/create',
        name: 'RateCreate',
        component: () =>
            import ('./views/admin/RateCreate.vue'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'tours/:tourId/rates/:rateId/edit',
        name: 'RateEdit',
        component: () =>
            import ('./views/admin/RateEdit.vue'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'transport',
        name: 'Transport',
        component: () =>
            import ('./views/admin/Transport'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'transport/create',
        name: 'TransportCreate',
        component: () =>
            import ('./views/admin/TransportCreate'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'tours/:Id/transport/edit',
        name: 'TransportEdit',
        component: () =>
            import ('./views/admin/TransportEdit'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'extras',
        name: 'Extras',
        component: () =>
            import ('./views/admin/Extras'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'extras/create',
        name: 'ExtraCreate',
        component: () =>
            import ('./views/admin/ExtraCreate'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'extras/:id/edit',
        name: 'ExtraEdit',
        component: () =>
            import ('./views/admin/ExtraEdit'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'extras/:id/images',
        name: 'ExtraImages',
        component: () =>
            import ('./views/admin/ExtraImages'),
        meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'zone',
        name: 'Zone',
        component: () => import ('./views/admin/Zone'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        }
      },
      {
        path: 'zone/create',
        name: 'ZoneCreate',
        component: () => import ('./views/admin/ZoneCreate'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        }
      },
      {
        path: 'zone/:id/edit',
        name: 'ZoneEdit',
        component: () => import ('./views/admin/ZoneEdit'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        }
      },
      {
        path: 'categories-extras',
        name: 'CategoriesExtras',
        component: () =>
          import ('./views/admin/CategoriesExtras'),
          meta: {
            requiresAuth: true,
            role: 'admin'
          }
      },
      {
        path: 'categories-extras/create',
        name: 'CategoriesExtrasCreate',
        component: () =>
          import ('./views/admin/CategoryExtraCreate'),
          meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'categories-extras/:id/edit',
        name: 'CategoriesExtrasEdit',
        component: () =>
          import ('./views/admin/CategoryExtraEdit'),
          meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'currencies',
        name: 'Currencies',
        component: () =>
          import ('./views/admin/Currencies'),
          meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'currencies/create',
        name: 'CurrencyCreate',
        component: () =>
          import ('./views/admin/CurrencyCreate'),
          meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'currencies/:id/edit',
        name: 'CurrencyEdit',
        component: () =>
          import ('./views/admin/CurrencyEdit'),
          meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'divers',
        name: 'Divers',
        component: () =>
          import ('./views/admin/Divers'),
          meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      {
        path: 'divers/rates',
        name: 'DiversRates',
        component: () =>
          import ('./views/admin/DiversRates'),
          meta: {
            requiresAuth: true,
            role: 'admin'
        }
      },
      /**reportes*/
      {
        path: 'reports/customers',
        name: 'AdminCustomersReport',
        component: () => import ('./views/admin/CustomersReport.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
      /**users*/
      {
        path: 'users',
        name: 'AdminUser',
        component: () => import ('./views/admin/Users.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
      {
        path: 'users/create',
        name: 'AdminUserCreate',
        component: () => import ('./views/admin/UserCreate.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
      {
        path: 'users/:id/edit',
        name: 'AdminUserEdit',
        component: () => import ('./views/admin/UserEdit.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
      /**users permissions*/
      {
        path: 'users/permissions',
        name: 'AdminUserPermissions',
        component: () => import ('./views/admin/UsersPermissions.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
      /** CMS **/
      {
        path: 'cms/landings',
        name: 'AdminCMSLandings',
        component: () => import ('./views/admin/cms/landings/Index.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
      {
        path: 'cms/landings/create',
        name: 'AdminCMSLandingCreate',
        component: () => import ('./views/admin/cms/landings/Create.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
      {
        path: 'cms/landings/:id/edit',
        name: 'AdminCMSLandingEdit',
        component: () => import ('./views/admin/cms/landings/Edit.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
      {
        path: 'cms/landings/:id/extras',
        name: 'AdminCMSLandingEditExtras',
        component: () => import ('./views/admin/cms/landings/EditExtras.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
      {
        path: 'cms/landings/:id/images',
        name: 'AdminCMSLandingEditImages',
        component: () => import ('./views/admin/cms/landings/EditImages.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
      {
        path: 'cms/home-landings-title',
        name: 'AdminCMSHomeLandingsTitle',
        component: () => import ('./views/admin/cms/HomeLandingsTitle.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
      {
        path: 'cms/home-tours',
        name: 'AdminCMSHomeTours',
        component: () => import ('./views/admin/cms/HomeTours.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
      {
        path: 'cms/home-landings',
        name: 'AdminCMSHomeLandings',
        component: () => import ('./views/admin/cms/HomeLandings.vue'),
        meta: {
          requiresAuth: true,
          role: 'admin'
        },
      },
    ]
  },
  {
    path: '/backend/taquilla',
    //name: 'Admin',
    component: () => import ('./views/taquilla/Taquilla.vue'),
    meta: {
      requiresAuth: true,
      role: 'taquilla'
    },
    children: [
      {
        path: '',
        name: 'TaquillaDashboard',
        component: () => import ('./views/taquilla/TaquillaDashboard.vue'),
        meta: {
          requiresAuth: true,
          role: 'taquilla'
        }
      },
      {
        path: 'reservations',
        name: 'TaquillaReservations',
        component: () => import ('./views/taquilla/Reservations.vue'),
        meta: {
          requiresAuth: true,
          role: 'taquilla'
        },
      },
      {
        path: 'reservations/search',
        name: 'TaquillaReservationsSearch',
        component: () => import ('./views/taquilla/ReservationsSearch.vue'),
        meta: {
          requiresAuth: true,
          role: 'taquilla'
        },
      },
      {
        path: 'reservations/add',
        name: 'TaquillaReservationAdd',
        component: () => import ('./views/taquilla/ReservationAdd.vue'),
        meta: {
          requiresAuth: true,
          role: 'taquilla'
        },
      },
      {
        path: 'reservations/:id/edit',
        name: 'TaquillaReservation',
        component: () => import ('./views/taquilla/Reservation.vue'),
        meta: {
          requiresAuth: true,
          role: 'taquilla'
        },
      },
      {
        path: 'reservations/:id/checkin',
        name: 'TaquillaReservationCheckin',
        component: () => import ('./views/taquilla/ReservationCheckin.vue'),
        meta: {
          requiresAuth: true,
          role: 'taquilla'
        },
      },
      {
        path: 'reservations/:id/responsives',
        name: 'TaquillaReservationResponsives',
        component: () => import ('./views/taquilla/ReservationResponsives.vue'),
        meta: {
          requiresAuth: true,
          role: 'taquilla'
        },
      },
      {
        path: 'reports/daily',
        name: 'TaquillaDailyReport',
        component: () => import ('./views/taquilla/DailyReport.vue'),
        meta: {
          requiresAuth: true,
          role: 'taquilla'
        },
      },
      {
        path: 'reports/tours',
        name: 'TaquillaToursReport',
        component: () => import ('./views/taquilla/ToursReport.vue'),
        meta: {
          requiresAuth: true,
          role: 'taquilla'
        },
      },
      {
        path: 'reports/payment',
        name: 'TaquillaPaymentsReport',
        component: () => import ('./views/taquilla/PaymentsReport.vue'),
        meta: {
          requiresAuth: true,
          role: 'taquilla'
        },
      },
      {
        path: 'reports/reservations-status',
        name: 'TaquillaReservationStatusReport',
        component: () => import ('./views/taquilla/ReservationStatusReport.vue'),
        meta: {
          requiresAuth: true,
          role: 'taquilla'
        },
      },
    ]
  },
  {
    path: '/backend/divers',
    //name: 'Admin',
    component: () => import ('./views/divers/Divers.vue'),
    meta: {
      requiresAuth: true,
      role: 'diver'
    },
    children: [
      {
        path: '',
        name: 'DiversDashboard',
        component: () => import ('./views/divers/Dashboard.vue'),
        meta: {
          requiresAuth: true,
          role: 'diver'
        }
      },
      {
        path: 'reservations',
        name: 'DiversReservations',
        component: () => import ('./views/divers/Reservations.vue'),
        meta: {
          requiresAuth: true,
          role: 'diver'
        }
      },
      {
        path: 'reservationsList',
        name: 'DiversReservationsList',
        component: () => import ('./views/divers/ReservationsList.vue'),
        meta: {
          requiresAuth: true,
          role: 'diver'
        }
      },
      {
        path: 'reservations/:id',
        name: 'DiversReservation',
        component: () => import ('./views/divers/Reservation.vue'),
        meta: {
          requiresAuth: true,
          role: 'diver'
        }
      },
    ]
  },
  {
    path: "*",
    component: () =>
      import ('./views/PageNotFound.vue')
  }
];

const router = new VueRouter({
    mode: 'history',
    routes: routes,
})

// este compruba si te deja acceder o no despues de un login 
router.beforeEach((to, from, next) => {
  let record = to.matched.find(record => record.meta.requiresAuth)
  if (record) {
    let meta = record.meta;
    let currentUser = firebase.auth().currentUser;
    if (!currentUser && meta.requiresAuth) {
        next('/');
    } else if (currentUser && meta.requiresAuth) {
      let usersModel = new User();
      //usersModel.findByUID(currentUser.uid).get().then(qSnapshot => {
      usersModel.findByUID(currentUser.uid).onSnapshot(qSnapshot => {
        if (qSnapshot.empty) {
          next('/forbidden');
        } else {
          qSnapshot.forEach(doc => {
            if (doc.exists) {
              if (meta.role == doc.data().role) {
                next();
              } else {
                next('/forbidden');
              }
            } else {
              next('/forbidden');
            }
          })
        }
      })
    }
    next();
  } else {
    next();
  }
})
export default router