<template>
  <component :is="layout"></component>
</template>
<script>
  import Admin from './components/layouts/Admin'
  import Front from './components/layouts/Front'
  import Taquilla from './components/layouts/Taquilla'
  import Divers from './components/layouts/Divers'
  import {mapState} from 'vuex'
  export default {
    components: {Admin, Front, Taquilla, Divers},
    computed: mapState(['layout'])
    /*data () {
      return {
        layout: 'front'
      }
    }*/
  }
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
.v-card__title{
  padding-top: 5px;
  padding-bottom: 5px;
}
.v-timeline-item__body .v-card__title {
  padding-top: 2px;
  padding-bottom: 2px;
}
.v-timeline-item .v-card__title {
  font-size: 0.9rem;
}
.v-expansion-panel-content__wrap{
  padding-top: 16px;
}
.v-expansion-panel-header{
  padding: 5px 24px;
  min-height: 38px;
}
.v-expansion-panel--active > .v-expansion-panel-header{
  min-height: auto;
  min-height: 38px;
}
</style>
