import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdiSvg',
    // default - only for display purposes
  },
	theme: {
    themes: {
      light: {
        //error
        //success
        //info
        //warning
        //primary
        //secondary
        primary: '#006636',
        //warning: '#FF9800',
      },
    },
  },
});
