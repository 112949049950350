import firebase from 'firebase/app';
import 'firebase/firestore';
//import Vue from 'vue';
import lodash from 'lodash';

export default class BaseModel {
  _db;
  _options = {};
  constructor(options) {
    this._db = firebase.firestore();
    if (!lodash.isUndefined(options)) {
        this._options = {...options };
    }
  }
  get db() {
      return this._db;
  }
}
/*export default function BaseModel = (function (customOptions) {
}())*/