import Vue from 'vue'
import vuetify from './plugins/vuetify'
import App from './App.vue'
import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/auth';
import vueRounter from 'vue-router'
import router from './router.js';
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import VuejsDialog from "vuejs-dialog"
import 'vuejs-dialog/dist/vuejs-dialog.min.css';
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'
import Vue2Editor from "vue2-editor";

import VueCountryCode from "vue-country-code";


import { store } from './store' // importamos el modulo de store

import PortalVue from 'portal-vue'

//import Vue from 'vue'
import VueMoment from 'vue-moment'
const moment = require('moment');
require('moment/locale/es')

Vue.use(VueMoment, {
  moment,
})
Vue.use(VueCountryCode);

Vue.use(PortalVue)
// Init plugin
Vue.use(Loading);
Vue.component('Loading', Loading)
Vue.use(VuejsDialog, {
  html: true,
  loader: false,
  okText: 'Continuar',
  cancelText: 'Cancelar',
  /*animation: 'bounce', 
  message: 'Some message'*/
})
    // esta es la instancia donde  le decimos a viu donde pone los componentes
Vue.config.productionTip = false

Vue.use(vueRounter);

Vue.use(VueSidebarMenu);
Vue.use(Vue2Editor);

var app = '';

firebase.initializeApp(require('./FirebaseConfig.js').credentials);

firebase.auth().onAuthStateChanged(() => {
    if (!app) {
      app = new Vue({
          router,
          store,
          vuetify,
          render: h => h(App)
      }).$mount('#app')
    }
})
