/*'use strict'

import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
  namespaced: true,
  state: {
    dialogConfirmationInfo: false
  },
  mutations: {
    SET_DIALOG_CONFIRMED_INFO (state, dialog) {
      state.dialogConfirmationInfo = dialog
    }
  },
  actions:{
    setDialogConfirmationInfo(context, dialog) {
      context.commit('SET_DIALOG_CONFIRMED_INFO', dialog);
    }
  }
})
*/
// initial state
// shape: [{ id, quantity }]
const state = () => ({
  dialogConfirmationInfo: false
})

// getters
const getters = {
  dialogConfirmationInfo: (state/*, getters, rootState*/) => {
    return state.dialogConfirmationInfo
  },
  /*
  cartTotalPrice: (state, getters) => {
    return getters.cartProducts.reduce((total, product) => {
      return total + product.price * product.quantity
    }, 0)
  }*/
}

// actions
const actions = {
  setDialogConfirmationInfo ({ commit }, dialog) {
    commit('setDialogConfirmationInfo', dialog);
  },
}

// mutations
const mutations = {
  setDialogConfirmationInfo (state, dialog) {
    state.dialogConfirmationInfo = dialog
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}