import BaseModel from './BaseModel';
//import Vue from 'vue';
//import lodash from 'lodash';
import {DataConverter as UserDataConverter} from '@/FirestoreDataConverters/User.js';


export default class User extends BaseModel{
  
  constructor(options) {
    super(options);
  }
  findById(docId) {
    return this.db.collection('users').doc(docId).withConverter(UserDataConverter);
  }
  findByEmail(email) {
    return this.db.collection('users').where('email', '==', email).withConverter(UserDataConverter);
  }
  findByUID(uid) {
    return this.db.collection('users').where('uid', '==', uid).withConverter(UserDataConverter);
  }
  getAll() {
    return this.db.collection('users').withConverter(UserDataConverter);
  }
  getDivers() {
    return this.db.collection('users').where('role', '==', 'diver').withConverter(UserDataConverter);
  }
  getUsersPermissions(permission) {
    return this.db.collection('users').where('hasPermissions', '==' , permission).withConverter(UserDataConverter);
  }
  update(docId, data) {
    return this.db.collection('users').doc(docId).update(data);
  }
  add(data) {
    if (data.status || data.status == 'active') {
      data.status = 'active'
    } else {
      data.status = 'inactive'
    }
    return this.db.collection('users').add(data);
  }
}